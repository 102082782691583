import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"
import scrollTo from "gatsby-plugin-smoothscroll"
import Arrow from "../../components/arrow"

class Platform extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Online Learning Platform"}
          titleDesc={
            "Want to get digital schooling experiences? Fuse Classroom helps transform online learning experience for schools, colleges, universities from start to finish."
          }
          titleKeywords={
            "online classes, lms, fuse classroom, erp, sis,online exams, school management systems, earning management system, online learning platform, online education platform"
          }
          fbDes={"Transform your schools, colleges and universities to online classes from start to finish."}
          twitDesc={"Transform your schools, colleges and universities to online classes from start to finish."}
          seoLink={"platform/"}
        />
        <div className="s-scroll">
          {/* top section */}
          <section role="top--section" className="features">
            {/* content */}
            <article className="container">
              <Row className="align-items-center py-md-5">
                <Col lg="10" className="text-white py-md-5">
                  <div className="my-5">
                    <h1 className="text-white">
                      Taking Schools, Colleges and Universities Online from Start to Finish
                    </h1>
                    <div className="h3 mt-4 font-weight-semibold">Transformation Made Easy.</div>

                    <div className="main-cta">
                      <Link
                        to="/request-for-more-info/"
                        className="btn btn-warning mr-3 mt-4 btn-lg font-weight-semibold"
                        // onClick={() => FormEventAnalytics("Platform_Top_Request_Demo")}
                        state={{ page: "platform" }}
                        id="request--for-more-info__top"
                      >
                        REQUEST FOR MORE INFO
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </article>
          </section>
          {/* top section end */}

          {/* Artificial Intelligence */}
          <section className="bg-light-green pb-5 d-none d-md-block" id="ai-info">
            <article className="container ai-features">
              <Row>
                <Col sm="12" className="py-4 mb-5 text-center">
                  <button onClick={() => scrollTo("#AI-Assisted-Learning")} className="platform-ai">
                    <h2 className="text-center ai-features-title">
                      Artificial <br /> Intelligence
                    </h2>
                  </button>
                </Col>

                <div className="my-3 col col-custom">
                  <button onClick={() => scrollTo("#fuse-classroom")} className="platform-ai">
                    <Card>
                      <Card.Body>
                        <div className="ai-image">
                          <img src="/images/icon/classroom.svg" alt="LMS" width="45" height="auto" />
                        </div>
                        <Card.Title className="ai-title">LMS</Card.Title>
                        <div className="small">
                          Jumpstart the digital school experience with our Learning Management System (LMS) and give
                          students a new and exciting way to learn.
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <img
                          src="/images/implementation/chevron-down.svg"
                          className="img-fluid"
                          width="13"
                          height="auto"
                        />
                      </Card.Footer>
                    </Card>
                  </button>
                </div>

                <div className="my-3 col col-custom">
                  <button onClick={() => scrollTo("#online-live")} className="platform-ai">
                    <Card>
                      <Card.Body>
                        <div className="ai-image">
                          <img src="/images/icon/live-classes.svg" alt="Online Live" width="45" height="auto" />
                        </div>
                        <Card.Title className="ai-title">Online Live</Card.Title>
                        <div className="small">
                          Conduct online classes in a matter of minutes with our platform that makes scheduling and
                          attending classes easy for teachers and students.
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <img
                          src="/images/implementation/chevron-down.svg"
                          className="img-fluid"
                          width="13"
                          height="auto"
                        />
                      </Card.Footer>
                    </Card>
                  </button>
                </div>

                <div className="my-3 col col-custom">
                  <button onClick={() => scrollTo("#SIS")} className="platform-ai">
                    <Card>
                      <Card.Body>
                        <div className="ai-image">
                          <img
                            src="/images/icon/admissions.svg"
                            alt="SIS"
                            className="img-fluid"
                            width="45"
                            height="auto"
                          />
                        </div>
                        <Card.Title className="ai-title">SIS</Card.Title>
                        <div className="small">
                          AI-powered Student Information System tracks academic and student information in one
                          centralized location.
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <img
                          src="/images/implementation/chevron-down.svg"
                          className="img-fluid"
                          width="13"
                          height="auto"
                        />
                      </Card.Footer>
                    </Card>
                  </button>
                </div>

                <div className="my-3 col col-custom">
                  <button onClick={() => scrollTo("#exams")} className="platform-ai">
                    <Card>
                      <Card.Body>
                        <div className="ai-image">
                          <img src="/images/icon/instructor.svg" alt="Online Exams" width="45" height="auto" />
                        </div>
                        <Card.Title className="ai-title">Online Exams</Card.Title>
                        <div className="small">
                          Students can study with digital flashcards and take their exams online.
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <img
                          src="/images/implementation/chevron-down.svg"
                          className="img-fluid"
                          width="13"
                          height="auto"
                        />
                      </Card.Footer>
                    </Card>
                  </button>
                </div>

                <div className="my-3 col col-custom">
                  <button onClick={() => scrollTo("#community")} className="platform-ai">
                    <Card>
                      <Card.Body>
                        <div className="ai-image">
                          <img src="/images/icon/ta.svg" alt="community" width="45" height="auto" />
                        </div>
                        <Card.Title className="ai-title">Community</Card.Title>
                        <div className="small">
                          Community engagement is a crucial part of education and this experience is extended to digital
                          school through our platform.
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <img
                          src="/images/implementation/chevron-down.svg"
                          className="img-fluid"
                          width="13"
                          height="auto"
                        />
                      </Card.Footer>
                    </Card>
                  </button>
                </div>

                <div className="my-3 col col-custom">
                  <button onClick={() => scrollTo("#school-management")} className="platform-ai">
                    <Card>
                      <Card.Body>
                        <div className="ai-image">
                          <img src="/images/icon/course-coordinator.svg" alt="ERP" width="45" height="auto" />
                        </div>
                        <Card.Title className="ai-title">School Management</Card.Title>
                        <div className="small">
                          Bring your School Management System under one operationally effective AI platform at scale.
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <img
                          src="/images/implementation/chevron-down.svg"
                          className="img-fluid"
                          width="13"
                          height="auto"
                        />
                      </Card.Footer>
                    </Card>
                  </button>
                </div>

                <div className="my-3 col col-custom">
                  <button onClick={() => scrollTo("#admission")} className="platform-ai">
                    <Card>
                      <Card.Body>
                        <div className="ai-image">
                          <img src="/images/icon/admissions.svg" alt="admission" width="45" height="auto" />
                        </div>
                        <Card.Title className="ai-title">Admission</Card.Title>
                        <div className="small">Make your admission process fast and easy through our platform.</div>
                      </Card.Body>
                      <Card.Footer>
                        <img
                          src="/images/implementation/chevron-down.svg"
                          className="img-fluid"
                          width="13"
                          height="auto"
                        />
                      </Card.Footer>
                    </Card>
                  </button>
                </div>
              </Row>
            </article>
          </section>
          {/* Artificial Intelligence End */}

          {/* AI Assisted Learning */}
          <section className="pt-5" id="AI-Assisted-Learning">
            <article className="container">
              <Row className="align-items-center">
                <Col md={{ span: 8, offset: 2 }}>
                  <div className="my-4 text-center">
                    <div className="my-4 title text-green">AI Assisted Learning</div>
                    <div className="h4 font-weight-normal">
                      Embedded in Fuse Classroom are AI features including Student Status, Activity Recommendation,
                      Automated Discussion Forums, Question Answering Systems, and a Chatbot Interface. These features
                      help you assess student progress, recommend study material, or automatically answer student
                      questions.
                    </div>
                  </div>
                </Col>
              </Row>
            </article>
          </section>
          {/* AI Assisted Learning end */}

          {/* Learning Management System (LMS)*/}
          <section id="fuse-classroom" className="py-5">
            <article className="container py-2">
              <Row className="align-items-center">
                <Col md={{ span: 6, offset: 1, order: 2 }}>
                  <img
                    src="/images/platform/lms/lms-main.webp"
                    alt="Learning Management System (LMS)"
                    className="img-fluid"
                    width="540"
                    height="auto"
                  />
                </Col>
                <Col md={{ span: 5, order: 1 }}>
                  <div className="my-4">
                    <div className="mb-2">
                      <img src="/images/icon/classroom.svg" alt="icon" width="45" height="auto" />
                    </div>
                    <h2 className="my-4">Learning Management System (LMS)</h2>
                    <div className="h5 my-4 text-black font-weight-normal">
                      Jumpstart your digital school experience with a Learning Management System (LMS) that gives
                      students a new and exciting way to learn. Continue reading for more information about the types of
                      tools and features included.
                    </div>
                    <Link
                      to="learning-management-system/"
                      className="h5 font-weight-semibold text-success"
                      // onClick={() => FormEventAnalytics("Platform_Learn_More")}
                      state={{ page: "Platform_Learn_More" }}
                      id="learn-more__lms"
                    >
                      LEARN MORE <Arrow />
                    </Link>
                  </div>
                </Col>
              </Row>
            </article>
          </section>
          {/* Learning Management System (LMS) */}

          {/* Online Live */}
          <section id="online-live" className="py-5 bg-80 left">
            <article className="container">
              <Row className="align-items-center">
                <Col md={{ span: 6 }}>
                  <img
                    src="/images/platform/onlinelive/online-live.webp"
                    alt="Online Live"
                    className="img-fluid"
                    width="540"
                    height="auto"
                  />
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                  <div className="my-4">
                    <div className="mb-2">
                      <img src="/images/icon/live-classes.svg" alt="Online Live" width="45" height="auto" />
                    </div>
                    <h2 className="my-4">Online Live</h2>
                    <div className="h5 my-4 text-black font-weight-normal">
                      Offer live online classes in a matter of minutes with our Zoom integrated platform equipped with
                      the best tools instructors need.
                    </div>
                    <Link
                      to="online-live/"
                      className="h5 font-weight-semibold text-success"
                      // onClick={() => FormEventAnalytics("Platform_Learn_More")}
                      state={{ page: "Platform_Learn_More" }}
                      id="learn-more__online-live"
                    >
                      LEARN MORE <Arrow />
                    </Link>
                  </div>
                </Col>
              </Row>
            </article>
          </section>
          {/* Online Live */}

          {/* Student Information System (SIS) */}
          <section id="SIS" className="py-5">
            <article className="container">
              <Row className="align-items-center">
                <Col md={{ span: 6, offset: 1, order: 2 }}>
                  <img
                    src="/images/platform/sis/sis.webp"
                    alt="Student Information System (SIS)"
                    className="img-fluid"
                    widht="540"
                    height="auto"
                  />
                </Col>
                <Col md={{ span: 5, order: 1 }}>
                  <div className="my-4">
                    <div className="mb-2">
                      <img src="/images/icon/admissions.svg" alt="icon" width="45" height="auto" />
                    </div>
                    <h2 className="my-4">Student Information System (SIS)</h2>
                    <div className="h5 my-4 text-black font-weight-normal">
                      AI-powered Student Information System tracks academic and student information in one centralized
                      location. See the features below for a full list of information tracked by the SIS.
                    </div>
                    <Link
                      to="student-information-system/"
                      className="h5 font-weight-semibold text-success"
                      // onClick={() => FormEventAnalytics("Platform_Learn_More")}
                      state={{ page: "Platform_Learn_More" }}
                      id="learn-more__sis"
                    >
                      LEARN MORE <Arrow />
                    </Link>
                  </div>
                </Col>
              </Row>
            </article>
          </section>
          {/* Student Information System (SIS) */}

          {/* Exams */}
          <section className="py-5 bg-80 left" id="exams">
            <article className="container">
              <Row className="align-items-center">
                <Col md={{ span: 6 }}>
                  <img
                    src="/images/platform/online-exams/online-exams.webp"
                    alt="Online Exams"
                    className="img-fluid"
                    width="540"
                    height="auto"
                  />
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                  <div className="my-4">
                    <div className="mb-2">
                      <img src="/images/icon/instructor.svg" alt="icon" width="45" height="auto" />
                    </div>
                    <h2 className="my-4">Online Exams</h2>
                    <div className="h5 my-4 text-black font-weight-normal">
                      The Online Exams feature gives students access to a library of study materials, such as digital
                      flashcards and gamified exams. These study materials ensure students are prepared for their
                      quizzes, assignments, and exams.
                    </div>
                    <Link
                      to="online-exams/"
                      className="h5 font-weight-semibold text-success"
                      // onClick={() => FormEventAnalytics("Platform_Learn_More")}
                      state={{ page: "Platform_Learn_More" }}
                      id="learn-more__online-exam"
                    >
                      LEARN MORE <Arrow />
                    </Link>
                  </div>
                </Col>
              </Row>
            </article>
          </section>
          {/* Exams */}

          {/* Community */}
          <section className="py-5" id="community">
            <article className="container">
              <Row className="align-items-center">
                <Col md={{ span: 6, offset: 1, order: 2 }}>
                  <img
                    src="/images/platform/community/community.webp"
                    alt="Community"
                    className="img-fluid"
                    width="540"
                    height="auto"
                  />
                </Col>
                <Col md={{ span: 5, order: 1 }}>
                  <div className="my-4">
                    <div className="mb-2">
                      <img src="/images/icon/ta.svg" alt="icon" width="45" height="auto" />
                    </div>
                    <h2 className="my-4">Community</h2>
                    <div className="h5 my-4 text-black font-weight-normal">
                      Extend your school’s community online. Fuse Classroom helps create a safe online community for
                      students and other faculty.
                    </div>
                    <Link
                      to="community/"
                      className="h5 font-weight-semibold text-success"
                      // onClick={() => FormEventAnalytics("Platform_Learn_More")}
                      state={{ page: "Platform_Learn_More" }}
                      id="learn-more__community"
                    >
                      LEARN MORE <Arrow />
                    </Link>
                  </div>
                </Col>
              </Row>
            </article>
          </section>
          {/* Community */}

          {/* School Management (ERP) */}
          <section className="py-5 bg-80 left" id="school-management">
            <article className="container">
              <Row className="align-items-center">
                <Col md={{ span: 6 }}>
                  <img
                    src="/images/platform/erp/erp.webp"
                    alt="School Management (ERP)"
                    className="img-fluid"
                    width="540"
                    height="auto"
                  />
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                  <div className="my-4">
                    <div className="mb-2">
                      <img src="/images/icon/course-coordinator.svg" alt="icon" width="45" height="auto" />
                    </div>
                    <h2 className="my-4">School Management (ERP)</h2>
                    <div className="h5 my-4 text-black font-weight-normal">
                      School Management Systems is one AI platform provide effective and scalable operations. Continue
                      reading to see the different departmental dashboards that can be included in your School
                      Management system.
                    </div>
                    <Link
                      to="school-management/"
                      className="h5 font-weight-semibold text-success"
                      // onClick={() => FormEventAnalytics("Platform_Learn_More")}
                      state={{ page: "Platform_Learn_More" }}
                      id="learn-more__school-management"
                    >
                      LEARN MORE <Arrow />
                    </Link>
                  </div>
                </Col>
              </Row>
            </article>
          </section>
          {/* School Management (ERP) */}

          {/* Admission */}
          <section className="py-5" id="admission">
            <article className="container">
              <Row className="align-items-center">
                <Col md={{ span: 6, offset: 1, order: 2 }}>
                  <img
                    src="/images/platform/admission/admission.webp"
                    alt="Admissions"
                    className="img-fluid"
                    width="540"
                    height="auto"
                  />
                </Col>
                <Col md={{ span: 5, order: 1 }}>
                  <div className="my-4">
                    <div className="mb-2">
                      <img src="/images/icon/admissions.svg" alt="icon" width="45" height="auto" />
                    </div>
                    <h2 className="my-4">Admission</h2>
                    <div className="h5 my-4 text-black font-weight-normal">
                      Greatly reduce the operational overhead for the admissions process, from tracking applications to
                      collecting payments and sending final acceptance letters.
                    </div>
                    <Link
                      to="admission/"
                      className="h5 font-weight-semibold text-success"
                      // onClick={() => FormEventAnalytics("Platform_Learn_More")}
                      state={{ page: "Platform_Learn_More" }}
                      id="learn-more__admission"
                    >
                      LEARN MORE <Arrow />
                    </Link>
                  </div>
                </Col>
              </Row>
            </article>
          </section>
          {/* Admisson */}

          {/* call to action */}
          <section className="py-5">
            <article className="container">
              <Row className="justify-content-center">
                <Col lg="10">
                  <div className="rounded border border-success p-4 text-center">
                    <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                      Interested in more information about our platform?
                    </span>
                    {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-success mt-3 mt-xl-0"
                      // onClick={() => FormEventAnalytics("Platform_Bottom_Request_Demo")}
                      state={{ page: "platform" }}
                      id="request--for-more-info__bottom"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                  </div>
                </Col>
              </Row>
            </article>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Platform
